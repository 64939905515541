import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { Language } from "../../translations/translations";

interface Props {
  token: string;
  lang: Language;
  userId: string;
  reportId: string;
}

const setToken = (token: string) => sessionStorage.setItem("jwt", token);

const Redirect = (props: Props) => {
  const history = useHistory();

  const resolveUser = useCallback(async () => {
    try {
      setToken(props.token);
      const url = `/report/${props.lang}/${props.reportId}/${props.userId}/true`;
      return history.push(url);
    } catch (e) {
      console.error(e);
      return history.push("/");
    }
  }, [history, props]);

  useEffect(() => {
    if (!props.token) {
      return history.push("/");
    }

    resolveUser();
  }, [props.token, props.reportId, history, resolveUser]);

  return null;
};

export default Redirect;
