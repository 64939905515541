export const chartColors = [
  "#5dade2",
  "#af7ac5",
  "#f0b27a",
  "#81ecec",
  "#52be80",
  "#74b9ff",
  "#fdcb6e",
];

export const opaqueChartColors = [
  "rgba(93, 173, 226, 0.3)",
  "rgba(175, 122, 197, 0.3)",
  "rgba(240, 178, 122, 0.3)",
  "rgba(129, 236, 236, 0.3)",
  "rgba(82, 190, 128, 0.3)",
  "rgba(116, 185, 255, 0.3)",
  "rgba(253, 203, 110, 0.3)",
];
