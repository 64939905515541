import { Box } from "@chakra-ui/layout";
import { Img } from "@chakra-ui/react";
import { Report } from "../../../models/report";
import { Heading, HeadingThree } from "../Typography/typography";
import logo from "../../../assets/images/logo.png";
import { ElementBox } from "../atoms/Box";

interface Props {
  report: Report;
}

const ReportHeader = (props: Props) => {
  const { report } = props;

  return (
    <ElementBox flex="3" marginX="5" marginBottom="0">
      <Box d="flex" flexDir="row" alignItems="center" mb={3}>
        <Img marginRight={6} h="50" src={logo} />
        <HeadingThree
          fontFamily="Open Sans Light"
          letterSpacing="0.5px"
          size="md"
        >
          {report.templateName}
        </HeadingThree>
      </Box>
      <Box d="flex" flexDir="row" w="100%">
        <Heading letterSpacing="0.5px" size="lg">
          {report.teamName}
        </Heading>
      </Box>
    </ElementBox>
  );
};

export default ReportHeader;
