import { Report } from "../models/report";
import { ReportTrends, ReportWithTrends } from "../models/trends";

export const appendModulesWithTrends = (
  trends: ReportTrends,
  report: Report
): ReportWithTrends => {
  const reportWithTrends: ReportWithTrends = {
    ...report,
    trendingModules: report.resultData.map((module) => ({
      ...module,
      trend: trends.modules.find((m) => m.moduleId === module.moduleId) || null,
    })),
  };

  return reportWithTrends;
};
