import { Box } from "@chakra-ui/layout";
import { QuestionSetFreeTextAnswers } from "../../../../models/report";
import QuestionSetFreeTextResults from "./QuestionSetFreeTextResults";

interface Props {
  questionData: QuestionSetFreeTextAnswers[];
}

const FreeTextResults = ({ questionData }: Props) => (
  <Box padding="5">
    {questionData.map((set, i) => (
      <QuestionSetFreeTextResults results={set} key={`question-set-${i}`} />
    ))}
  </Box>
);

export default FreeTextResults;
