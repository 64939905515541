import { Box } from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/react";
import { BiChat } from "react-icons/bi/";
import { getUserLevel, userStore } from "../../../state/user";
import SelectLanguage from "./SelectLanguage";

interface Props {
  reportId: string;
  userId: string;
  isManager: boolean;
  toggleChat: () => void;
}

const ReportActions = (props: Props) => {
  const userLevel = userStore(getUserLevel);
  return (
    <Box
      alignSelf="center"
      marginX="5"
      d="flex"
      flexDir="row"
      alignItems="center"
    >
      <SelectLanguage
        reportId={props.reportId}
        userId={props.userId}
        isManager={props.isManager}
      />
      {["MANAGER", "FREE"].includes(userLevel) && (
        <IconButton
          onClick={props.toggleChat}
          variant="solid"
          isRound
          size="md"
          boxShadow="sm"
          aria-label="Toggle discussion box"
          fontSize="20"
          icon={<BiChat />}
        />
      )}
    </Box>
  );
};

export default ReportActions;
