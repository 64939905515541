import { TranslatableProperty } from "../models/workspace";
import translations from "./translations.json";

type Translations = typeof translations;
export type TranslationKey = keyof Translations;
export type Language = keyof TranslatableProperty;

export const getTranslation = (
  lang: keyof TranslatableProperty,
  key: keyof Translations
): string => {
  const options: TranslatableProperty | undefined = translations[
    key
  ] as TranslatableProperty;

  if (!options) {
    return "Missing property";
  }

  const translation = options[lang];
  if (!translation) {
    return "Missing translation";
  }

  return translation;
};
