import { Box as ChakraBox, BoxProps } from "@chakra-ui/layout";
import { useMemo } from "react";

type Props = BoxProps;

export const ElementBox: React.FC<Props> = (props: Props) => {
  const memoedProps = useMemo(() => props, [props]);
  return (
    <ChakraBox
      marginRight="5"
      marginBottom="5"
      padding="5"
      borderRadius="5"
      {...memoedProps}
    >
      {props.children}
    </ChakraBox>
  );
};
