import {
  QuestionResult,
  QuestionResults,
  QuestionSet,
  QuestionSetFreeTextAnswers,
  SurveyModule,
} from "../models/report";

export const isFreeTextQuestion = (question: QuestionResult) =>
  question.type === "freetext";

export const getFreeTextResults = (questionSets: QuestionSet[]) =>
  questionSets.reduce((res: QuestionSetFreeTextAnswers[], set: QuestionSet) => {
    const openQuestions = set.questions.filter(isFreeTextQuestion);
    if (openQuestions.length === 0) {
      return res;
    }
    return [...res, { questionSetName: set.name, questions: openQuestions }];
  }, []);

export const hasAverageValue = (
  section: SurveyModule | QuestionSet | QuestionResults
) => !!section.value;

export const containsOnlyFreeText = (
  module: SurveyModule,
  freeTextQuestions: QuestionSetFreeTextAnswers[]
) => !hasAverageValue(module) && freeTextQuestions.length !== 0;
