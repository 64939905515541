import { IconButton } from "@chakra-ui/button";
import { Box, Container, VStack } from "@chakra-ui/layout";
import { useContext, useEffect, useMemo, useRef } from "react";
import { BiArrowToBottom } from "react-icons/bi";
import { ChatMessage } from "../../../models/chat";
import { Translations } from "../../../provider/Language";
import { Heading, Text } from "../Typography/typography";

interface Props {
  feed: ChatMessage[];
  userId: string;
}

const DiscussionMessages = (props: Props) => {
  const { feed, userId } = props;
  const { k } = useContext(Translations);
  const focusElement = useRef<HTMLSpanElement | null>(null);

  const chatFeed = useMemo(() => feed.filter((f) => f.type === "chat"), [feed]);

  const handleFocusBottom = () => {
    if (focusElement.current) {
      focusElement.current.scrollIntoView();
    }
  };

  useEffect(() => {
    handleFocusBottom();
  }, [focusElement, chatFeed]);

  return (
    <Container position="relative" flex="1" display="flex">
      <VStack overflow="auto" spacing="5" flex="1" height="100%" paddingX="5">
        {chatFeed.map((item) => (
          <Box
            style={{
              alignSelf: item.responseId.includes(userId)
                ? "flex-end"
                : "flex-start",
            }}
            key={item.createdAt}
            width="80%"
            bg="white"
            borderRadius="md"
            boxShadow="sm"
            padding="5"
          >
            <Box d="flex" flexDir="row">
              <Heading flex="1" fontSize="14" fontFamily="Open Sans">
                {item.alias
                  ? item.alias
                  : item.responseId.includes(userId)
                  ? k("report.discussion.mymessage.label")
                  : k("report.discussion.anonymousresponder.indicator")}
              </Heading>
              <Text
                alignSelf="flex-end"
                fontFamily="Open Sans Light"
                fontSize="14"
              >
                {new Date(item.createdAt!).toLocaleDateString()}
              </Text>
            </Box>
            <Text fontFamily="Open Sans">{item.text}</Text>
          </Box>
        ))}
        <span ref={focusElement} />
      </VStack>
      <Box position="absolute" left="0" bottom="0" right="0">
        <IconButton
          onClick={handleFocusBottom}
          variant="solid"
          size="sm"
          isRound
          aria-label="Scroll to bottom"
          icon={<BiArrowToBottom />}
        />
      </Box>
    </Container>
  );
};

export default DiscussionMessages;
