import Icon from "@chakra-ui/icon";
import { Box } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { useContext } from "react";
import { BiWorld } from "react-icons/bi";
import { useHistory } from "react-router";
import { TranslatableProperty } from "../../../models/workspace";
import { Translations } from "../../../provider/Language";

interface Props {
  userId: string;
  reportId: string;
}

const translationDictionary = {
  fi: {
    fi: "Suomi",
    en: "Finnish",
    se: "Finska",
  },
  se: {
    fi: "Ruotsi",
    en: "Swedish",
    se: "Svenska",
  },
  en: {
    fi: "Englanti",
    en: "English",
    se: "Engelska",
  },
};

type DictType = typeof translationDictionary;

interface Props {
  reportId: string;
  userId: string;
  isManager: boolean;
}

const SelectLanguage = (props: Props) => {
  const { t, language, setLanguage } = useContext(Translations);
  const history = useHistory();

  const handleSelectLanguage = (lang: string) => {
    if (!["fi", "se", "en"].includes(lang)) {
      return;
    }
    setLanguage(lang as keyof TranslatableProperty);
    history.push(
      `/report/${lang}/${props.reportId}/${props.userId}${
        props.isManager ? "/true" : ""
      }`
    );
  };

  const options: Array<keyof DictType> = Object.keys(
    translationDictionary
  ) as Array<keyof DictType>;

  return (
    <Box d="flex" flexDir="row" marginRight="5" alignItems="center">
      <Icon as={BiWorld} color="gray.400" fontSize="30" marginRight="3" />
      <Select
        variant="filled"
        onChange={(e) => handleSelectLanguage(e.target.value)}
        value={language}
        size="md"
      >
        {options.map((opt) => (
          <option key={`translation-option-${opt}`} value={opt}>
            {t(translationDictionary[opt])}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default SelectLanguage;
