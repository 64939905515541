import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { Translations } from "../../../provider/Language";
import { BiAnalyse } from "react-icons/bi";
import { getSelfAssessment } from "../../../core/api";
import { ModuleWithTrends } from "../../../models/trends";
import { chartColors } from "../charts/colors";
import { QuestionSet } from "../../../models/report";
import { HorizontalGroupChart } from "../charts/Horizontal";

interface Props {
  module: ModuleWithTrends;
  surveyId: string;
}

const TrendPanel = (props: Props) => {
  const { k, t } = useContext(Translations);
  const [assessmentSets, setAssessmentSets] = useState<QuestionSet[]>([]);
  const { questionSets } = props.module;

  const questionSetData = useMemo(
    () =>
      questionSets.map((qs, idx) => ({
        name: t(qs.name),
        value: qs.value,
        color: chartColors[idx],
      })),
    [questionSets, t]
  );

  const assessmentSetData = useMemo(
    () =>
      assessmentSets.map((qs) => ({
        name: t(qs.name),
        value: qs.value,
        color: "#F7E05D",
      })),
    [assessmentSets, t]
  );

  useEffect(() => {
    getSelfAssessment(props.surveyId).then((res) => {
      if (res.resultData && res.resultData.length > 0) {
        setAssessmentSets(res.resultData[0].questionSets);
      }
    });
  }, [props.surveyId]);

  return (
    <AccordionItem borderTop="none" marginBottom="3">
      <AccordionButton borderRadius="md" boxShadow="sm" background="yellow.300">
        <Box
          fontFamily="Red Hat Display Bold"
          flex="1"
          textAlign="left"
          d="flex"
          flexDir="row"
          alignItems="center"
        >
          <Icon fontSize="20" as={BiAnalyse} marginRight="3" />
          {k("report.selfassessment.title")}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel paddingX="0">
        {assessmentSets.length > 0 ? (
          <HorizontalGroupChart
            chartData={[questionSetData, assessmentSetData]}
            height={20 * (questionSetData.length + assessmentSetData.length)}
          />
        ) : (
          <Text p={8} fontWeight="bold">
            {k("report.selfassessment.error")}
          </Text>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default TrendPanel;
