import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router";
import NotFound from "./ui/views/NotFound";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./ui/theme";
import ReportContainer from "./ReportContainer";
import Redirect from "./ui/views/Redirect";
import "./App.css";
import { Language } from "./translations/translations";
import Demo from "./ui/views/Demo/Demo";
import Login from "./ui/views/Login";

// Example route for a working survey:
// - /report/en/4d7e8b56/41696fd4
function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Switch>
          <Route
            path="/redirect/:lang/:reportId/:userId/:token"
            children={(props) => {
              return props.match ? (
                <Redirect
                  token={props.match.params.token}
                  lang={props.match.params.lang as Language}
                  userId={props.match.params.userId}
                  reportId={props.match.params.reportId}
                />
              ) : (
                <span>Incorrect route parameters.</span>
              );
            }}
          />
          <Route
            exact
            path="/report/:language/:reportId/:userId/:isAssessing?"
            children={(props) => {
              return props.match ? (
                <ReportContainer
                  reportId={props.match.params.reportId}
                  userId={props.match.params.userId}
                  isManager={!!props.match.params.isAssessing}
                  language={props.match.params.language as Language}
                />
              ) : (
                <span>Incorrect route parameters.</span>
              );
            }}
          />
          <Route path="/demo" component={Demo} />
          <Route path="/login" component={Login} />
          <Route path="/" component={NotFound} />
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

export default App;
