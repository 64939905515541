import { Center } from "@chakra-ui/layout";
import { Heading, Text } from "../components/Typography/typography";

const NotFound = () => {
  return (
    <Center flexDir="column" height="100vh">
      <Heading fontSize="25" padding="10">
        There's nothing here
      </Heading>
      <Text fontSize="18" padding="10">
        Make sure you have the correct path to the Thribune Survey Report you
        wish to look into.
      </Text>
    </Center>
  );
};

export default NotFound;
