import { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { ElementBox } from "../atoms/Box";
import { chartColors } from "./colors";

function createChartDatasets(data: Array<{ values: number[]; label: string }>) {
  const datasets = data.map((dataSet: any, index: number) => ({
    data: dataSet.values,
    label: dataSet.label,
    fill: false,
    backgroundColor: chartColors[index],
    borderColor: chartColors[index],
  }));
  return datasets;
}

const options = {
  scales: {
    y: {
      min: 0,
      max: 5,
    },
  },
};

export interface TrendDataType {
  values: number[];
  label: string;
}

interface Props {
  xAxis: string[];
  chartData: Array<TrendDataType>;
}

const LineChart = (props: Props) => {
  const datasets = useMemo(
    () => createChartDatasets(props.chartData),
    [props.chartData]
  );
  const data: Chart.ChartData = useMemo(
    () => ({
      labels: props.xAxis,
      datasets,
    }),
    [datasets, props.xAxis]
  );

  return (
    <ElementBox d="flex" padding="10" flexDir="row" justifyContent="flex-end">
      <Line data={data} options={{ ...options, animation: false } as any} />
    </ElementBox>
  );
};

export default LineChart;
