import { Link, Box, List, ListItem } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { useEffect, useState } from "react";
import { Report } from "../../../models/report";
import { ReportUser } from "../../../models/user";
import { getAvailableReports, surveyStore } from "../../../state/surveys";
import { teamStore } from "../../../state/teams";
import { userStore } from "../../../state/user";
import { Heading, Text } from "../../components/Typography/typography";

const createReportHref = (user: ReportUser, report: Report) => {
  const [userId] = user.userId.split("-");
  const [reportId] = report.surveyId.split("-");
  return `/report/en/${reportId}/${userId}/true`;
};

const DemoTeams = () => {
  const { user } = userStore();
  const [selectedTeam, setTeam] = useState<string | null>(null);
  const { teams, getTeams } = teamStore();
  const { getSurveys } = surveyStore();
  const surveys = surveyStore(getAvailableReports);

  useEffect(() => {
    if (teams.length === 0) {
      getTeams();
      return;
    }
    if (selectedTeam === null) {
      setTeam(teams[0].teamId);
    }
  }, [teams, getTeams, selectedTeam]);

  useEffect(() => {
    if (selectedTeam) {
      getSurveys(selectedTeam);
    }
  }, [selectedTeam, getSurveys]);

  return (
    <Box>
      <Heading fontSize="18" paddingY="5">
        Manager's Teams and reports
      </Heading>
      <Text fontSize="18" paddingBottom="5">
        Displays the last 10 (or less) available reports for this manager.
        Sorted here by team.
      </Text>
      <Tabs isLazy onChange={(idx) => setTeam(teams[idx].teamId)}>
        <TabList>
          {teams.map((team) => (
            <Tab key={team.teamId}>{team.name}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {teams.map((t) => (
            <TabPanel key={`panel-${t.teamId}`}>
              <List>
                {surveys.slice(0, 10).map((s) => (
                  <ListItem key={s.surveyId}>
                    <Link href={createReportHref(user!, s)}>
                      {s.templateName}: {s.name}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default DemoTeams;
