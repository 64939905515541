import create from "zustand";
import { fetchUser } from "../core/api";
import { ReportUser } from "../models/user";

interface State {
  user: ReportUser | null;
}

interface UserState extends State {
  getUser: () => Promise<void>;
  clearUser: () => void;
  setUser: (user: ReportUser) => void;
}

export const userStore = create<UserState>((set, get) => ({
  user: null,
  clearUser: () => set({ user: null }),
  getUser: async () => {
    const user = await fetchUser();
    return set({ user });
  },
  setUser: (user) => set({ user }),
}));

export const getUserLevel = (
  userState: UserState
): "MANAGER" | "ORGANIZATION" | "ADMIN" | "FREE" => {
  const { user } = userState;
  if (user) {
    return user.level;
  }
  return "FREE";
};
