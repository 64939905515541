import { get, post } from "./axios";
import { ChatMessage } from "../models/chat";
import { ReportTrends } from "../models/trends";
import { ReportUser, TokenUser } from "../models/user";
import { Report, SelfAssessment } from "../models/report";
import { Team } from "../models/team";

const baseUrl: string =
  process.env.REACT_APP_API_URL ?? "https://rest.blalab.com/dev";

export const getManagerReport = async (
  surveyId: string,
  responderShorthand: string
) => {
  const response = await get<Report[]>(
    `${baseUrl}/v2/survey/get?sid=${surveyId}`
  );
  return { report: response.data[0] };
};

export const getReport = async (
  surveyId: string,
  responderShorthand: string
) => {
  // Returns a single report with desired details. Uses shorthands
  const response = await get<Report & { responderId: string }>(
    `${baseUrl}/v2/ws/survey/get?sid=${surveyId}&rid=${responderShorthand}`
  );
  const { responderId, ...rest } = response.data;
  return { responderId, report: rest };
};

export const getFeed = async (responderId: string, surveyId: string) => {
  const result = await get<ChatMessage[]>(
    `${baseUrl}/v1/ws/comment?rid=${responderId}&sid=${surveyId}`
  ); // Only survey-shorthand
  return result.data;
};

export const getFeedWithToken = async (teamId: string) => {
  const result = await get<ChatMessage[]>(
    `${baseUrl}/v1/comment?tid=${teamId}`
  );
  return result.data;
};

export const getSelfAssessment = async (
  surveyId: string
): Promise<SelfAssessment> => {
  const response = await get<SelfAssessment>(
    `${baseUrl}/v1/self-assessment/${surveyId.split("-")[0]}`
  );
  return response.data;
};

export interface MessageInput {
  responseId: string;
  surveyId: string;
  text: string;
  teamId: string;
}

export const saveFeedMessage = (message: MessageInput) =>
  post(`${baseUrl}/v1/ws/comment/`, message);

export const saveFeedMessageWithToken = async (message: MessageInput) =>
  post(`${baseUrl}/v1/comment/`, message);

export const fetchUser = async () => {
  const result = await get<ReportUser>(`${baseUrl}/v1/user/`);
  return result.data;
};

export const getTrendsByRespondent = async (
  surveyId: string,
  responderId: string
) => {
  const result = await get<ReportTrends>(
    `${baseUrl}/v1/ws/trend/get?sid=${surveyId}&rid=${responderId}`
  );
  return result.data;
};

export const getTrends = async (surveyId: string, teamId: string) => {
  const result = await get<ReportTrends>(
    `${baseUrl}/v1/trend/get?sid=${surveyId}&tid=${teamId}`
  );
  return result.data;
};

export const loginUser = async (email: string, password: string) => {
  const body = JSON.stringify({
    type: "blalab",
    email,
    password,
  });

  const response = await post<TokenUser>(`${baseUrl}/v1/login/`, body);

  return response.data;
};

export const getTeams = async () => {
  const response = await get<{ teams: Team[] }>(`${baseUrl}/v1/team/`);
  return response.data.teams;
};

export const getSurveys = async (teamId: string) => {
  const surveys = await get<Report[]>(`${baseUrl}/v2/survey/get?tid=${teamId}`);
  return surveys.data;
};
