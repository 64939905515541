import {
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { BiBarChart } from "react-icons/bi/";
import { Translations } from "../../../provider/Language";
import { ElementBox } from "../atoms/Box";
import { Heading } from "../Typography/typography";
import { ReportWithTrends } from "../../../models/trends";
import ModuleInfo from "../ModuleInfo/ModuleInfo";

interface Props {
  report: ReportWithTrends;
  isManager: boolean;
}

const ReportDetails = (props: Props) => {
  const { t, k } = useContext(Translations);
  const { report } = props;
  const { resultData, trendingModules } = report;
  const tabLabels = useMemo(
    () => resultData.map((res) => t(res.name)),
    [resultData, t]
  );

  return (
    <ElementBox padding={{ lg: 10, md: 8, sm: 2 }} marginRight="0">
      <Heading
        marginBottom="10"
        color="blackAlpha.800"
        size="lg"
        d="flex"
        flexDir="row"
        alignItems="center"
      >
        <Icon
          color="blackAlpha.800"
          borderRadius="md"
          bgColor="yellow.100"
          fontSize="larger"
          padding="1"
          as={BiBarChart}
          marginRight="5"
        />
        {k("report.module.section.title")}
      </Heading>

      <Tabs
        colorScheme="blackAlpha"
        align="center"
        isLazy
        size="sm"
        variant="soft-rounded"
        width="100%"
      >
        <TabList fontFamily="Open Sans" flexDir={{ base: "column", md: "row" }}>
          {tabLabels.map((label) => (
            <Tab key={`tab-${label}`}>{label}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {trendingModules.map((module) => (
            <TabPanel paddingX="0" key={module.moduleId}>
              <ModuleInfo
                module={module}
                displaySelfAssessment={props.isManager && module.selfAssessment}
                surveyId={report.surveyId}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </ElementBox>
  );
};

export default ReportDetails;
