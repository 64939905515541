import { Box, Center } from "@chakra-ui/layout";
import { Icon, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { BiTask } from "react-icons/bi";
import { Report } from "../../../models/report";
import ReportOverview from "../ReportOverview/ReportOverview";
import { Heading } from "../Typography/typography";
import { useContext } from "react";
import { Translations } from "../../../provider/Language";

interface Props {
  report: Report;
}

const SurveyInfo = (props: Props) => {
  const { k } = useContext(Translations);
  const { report } = props;

  return (
    <Box d="flex" flexDir="column" padding={{ lg: 10, md: 8, sm: 2, base: 1 }}>
      <Heading size="lg" marginBottom="5">
        <Icon
          color="blackAlpha.800"
          borderRadius="md"
          bgColor="yellow.100"
          fontSize="larger"
          padding="1"
          as={BiTask}
          marginRight="5"
        />
        {report.name}
      </Heading>
      <Box
        d="flex"
        flexDir={{ lg: "row", md: "column", sm: "column", base: "column" }}
        alignItems="center"
      >
        <Box borderRadius="md" bgColor="whitesmoke" flex="1" width="100%">
          <Center
            flexDir="column"
            h="100%"
            flex="1"
            padding={{ lg: 10, md: 8, sm: 2, base: 2 }}
            justifyContent="center"
            alignItems="flex-start"
            borderRadius="md"
          >
            <Box marginY="5">
              <Stat>
                <StatLabel>{k("report.details.sent")}</StatLabel>
                <StatNumber>
                  {new Date(report.createdAt).toLocaleDateString()}
                </StatNumber>
              </Stat>
            </Box>
            <Box marginY="5">
              <Stat>
                <StatLabel>{k("report.details.respondents")}</StatLabel>
                <StatNumber>{report.participants}%</StatNumber>
              </Stat>
            </Box>
            <Box marginY="5">
              <Stat>
                <StatLabel>{k("report.details.index")}</StatLabel>
                <StatNumber>{report.blalabIndex}</StatNumber>
              </Stat>
            </Box>
          </Center>
        </Box>
        <ReportOverview report={report} />
      </Box>
    </Box>
  );
};

export default SurveyInfo;
