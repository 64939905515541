import React from "react";
import { TranslatableProperty } from "../models/workspace";
import {
  getTranslation,
  Language,
  TranslationKey,
} from "../translations/translations";

export type ContentTranslator = (
  content: TranslatableProperty | string
) => string;

interface Context {
  language: Language;
  setLanguage: (lang: Language) => void;
  t: (translation: TranslatableProperty | string) => string;
  k: (key: TranslationKey) => string;
}

const initialContext = {
  language: "fi" as Language,
  setLanguage: (lang: Language) => {
    throw Error("Not initialized");
  },
  t: (translation: TranslatableProperty | string) => {
    throw Error("Not initialized");
  },
  k: () => {
    throw Error("Not initialized");
  },
};

export const Translations = React.createContext<Context>(initialContext);

interface Props {
  lang: Language;
}

const LanguageProvider: React.FC<Props> = ({ children, lang }) => {
  const [language, updateLanguage] = React.useState<Language>(lang);

  const setLanguage = (lang: Language) => {
    updateLanguage(lang);
  };

  const getTranslationFromProperty = (
    translation: TranslatableProperty | string
  ) => {
    if (typeof translation === "string") {
      return translation;
    }
    if (language in translation) {
      return translation[language];
    }
    return "Unavailable translation";
  };

  const getTranslationByKey = (key: TranslationKey) =>
    getTranslation(language, key);

  return (
    <Translations.Provider
      value={{
        language,
        setLanguage,
        t: getTranslationFromProperty,
        k: getTranslationByKey,
      }}
    >
      {children}
    </Translations.Provider>
  );
};

export default LanguageProvider;
