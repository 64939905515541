import { Center } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { Report, SurveyModule } from "../../../models/report";
import { Translations } from "../../../provider/Language";
import { hasAverageValue } from "../../../utils/report";

import RadarChart from "../charts/Radar";

interface Props {
  report: Report;
}

const ReportOverview = (props: Props) => {
  const { report } = props;
  const { t } = useContext(Translations);
  const moduleData = useMemo(
    () =>
      report.resultData
        .filter(hasAverageValue) // value is nil when module does not have numeric questions
        .map((module: SurveyModule) => {
          return {
            name: t(module.name),
            value: module.value,
            description: t(module.moduleDescription),
          };
        }),
    [report, t]
  );

  return (
    <Center flex="1" maxW="80vw">
      <RadarChart chartData={moduleData} label={t(report.name)} />
    </Center>
  );
};

export default ReportOverview;
