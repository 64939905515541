import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Center } from "@chakra-ui/layout";
import { ChangeEvent, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { loginUser } from "../../core/api";
import { userStore } from "../../state/user";
import { setToken } from "../../utils/auth";
import { Card } from "../components/atoms/Card";
import { Heading } from "../components/Typography/typography";

const Login = () => {
  const userState = userStore();
  const navi = useHistory();
  const redirection = useMemo(() => {
    const queryParams = navi.location.search;
    const urlSearchParams = new URLSearchParams(queryParams);
    return urlSearchParams.get("redirection");
  }, [navi]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const loginDisabled = useMemo(() => !email || !password, [email, password]);

  const handleOnEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);
  const handleOnPasswordChange = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleLogin = async () => {
    if (loginDisabled) {
      return;
    }

    try {
      const user = await loginUser(email, password);
      setToken(user.bla_token);

      await userState.getUser();

      if (redirection) {
        return navi.push(redirection);
      }

      return navi.push("/");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Center flexDir="column" height="100vh" p="5">
      <Card maxW="500px">
        <Center flexDir="column">
          <form onSubmit={handleLogin}>
            <Heading my="5">Sign in to Thribune</Heading>
            <Input
              placeholder="Registered email"
              mb="5"
              type="email"
              onChange={handleOnEmailChange}
            />
            <Input
              placeholder="Password"
              mb="5"
              type="password"
              onChange={handleOnPasswordChange}
            />
            <Button onClick={handleLogin}>Sign in</Button>
          </form>
        </Center>
      </Card>
    </Center>
  );
};

export default Login;
