import * as React from "react";
import { Bar, defaults } from "react-chartjs-2";
import { ElementBox } from "../atoms/Box";

defaults.font.family = "Red Hat Display Bold";
defaults.font.size = 14;

interface Props {
  height: number;
  chartData: Array<{ name: string; value: number; description?: string }>;
}

function createChartDataObject(
  data: Array<{ name: string; value: number; color?: string }>
) {
  return {
    labels: data.map((item) => item.name),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: data.map((item) => item.color ?? "#ff1166"),
        borderColor: data.map((item) => item.color ?? "#ff1166"),
      },
    ],
  };
}

function createGroupedChartData(
  groups: Array<Array<{ name: string; value: number; color?: string }>>
) {
  const datasets = groups.map((group, idx) => ({
    data: group.map((item) => item.value),
    backgroundColor: group.map((item) => item.color ?? "#ff1166"),
    label: idx === 0 ? "Team answers" : "Self-assessment",
  }));

  return {
    labels: groups[0].map((item) => item.name),
    datasets,
  };
}

const options: Chart.ChartOptions = {
  indexAxis: "y",
  animation: false,
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 0,
      borderRadius: 5,
    },
  },
  scales: {
    x: {
      min: 0,
      max: 5,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
} as any;

export const BarChart = ({ chartData, height }: Props) => {
  const dataset = React.useMemo(
    () => createChartDataObject(chartData),
    [chartData]
  );
  const chartOptions = React.useMemo(() => options, []);
  return (
    <ElementBox d="flex" flexDir="row" justifyContent="flex-end">
      <Bar
        height={height}
        data={dataset}
        options={{ ...chartOptions } as any}
      />
    </ElementBox>
  );
};

interface GroupProps {
  height: number;
  chartData: Array<
    Array<{ name: string; value: number; description?: string }>
  >;
}

export const HorizontalGroupChart = ({ chartData, height }: GroupProps) => {
  const dataset = React.useMemo(
    () => createGroupedChartData(chartData),
    [chartData]
  );
  console.log(dataset);

  const chartOptions = React.useMemo(() => options, []);

  return (
    <ElementBox d="flex" flexDir="row" justifyContent="flex-end">
      <Bar
        height={height}
        data={dataset}
        options={{ ...chartOptions } as any}
      />
    </ElementBox>
  );
};

export default BarChart;
