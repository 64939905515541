import { Box, BoxProps } from "@chakra-ui/layout";

type Props = BoxProps;

export const Card: React.FC<Props> = (props: Props) => {
  return (
    <Box padding="5" borderRadius="5" boxShadow="md" bg="white" {...props}>
      {props.children}
    </Box>
  );
};
