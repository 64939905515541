import { Box } from '@chakra-ui/layout';
import React from 'react';

interface Props {
  value: string;
}

const FreeTextValue = ({ value }: Props) => (
  <Box
    paddingX={4}
    paddingY={4}
    boxShadow="md"
    borderRadius="lg"
    bgColor="blue.50"
  >
    {value}
  </Box>
);

export default FreeTextValue;
