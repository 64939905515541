import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Icon,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { BiBarChartSquare } from "react-icons/bi/";
import { Translations } from "../../../provider/Language";

import { Text } from "../Typography/typography";
import HorizontalBar from "../charts/Horizontal";
import { chartColors } from "../charts/colors";
import { ModuleWithTrends } from "../../../models/trends";
import TrendPanel from "./TrendPanel";
import SelfAssessmentPanel from "./SelfAssessmentPanel";
import { QuestionSetFreeTextAnswers } from "../../../models/report";
import {
  containsOnlyFreeText,
  getFreeTextResults,
  hasAverageValue,
} from "../../../utils/report";
import FreeTextResults from "./freeText/FreeTextResults";
import FreeTextPanel from "./freeText/FreeTextPanel";

interface Props {
  module: ModuleWithTrends;
  displaySelfAssessment: boolean;
  surveyId: string;
}

const ModuleInfo = (props: Props) => {
  const { module, surveyId } = props;
  const { trend, questionSets } = module;
  const { t, k } = useContext(Translations);

  const questionSetData = useMemo(
    () =>
      questionSets.filter(hasAverageValue).map((qs, idx) => ({
        name: t(qs.name),
        value: qs.value,
        color: chartColors[idx],
      })),
    [questionSets, t]
  );

  const perQuestionData = useMemo(
    () =>
      questionSets
        .filter(hasAverageValue)
        .map((qs, idx) => {
          const setColor = chartColors[idx];
          const questionData = qs.questions.map((q) => ({
            name: t(q.name),
            value: q.value,
            color: setColor,
          }));
          return questionData;
        })
        .flatMap((qd) => [...qd]),
    [questionSets, t]
  );

  const horizontalHeight = useMemo(
    () => (perQuestionData.length * 15 > 80 ? perQuestionData.length * 15 : 80),
    [perQuestionData]
  );

  const freeTextData: QuestionSetFreeTextAnswers[] = useMemo(
    () => getFreeTextResults(questionSets),
    [questionSets]
  );

  // display only free text results wihtout accordion if module does not have any numeric data
  if (containsOnlyFreeText(module, freeTextData)) {
    return <FreeTextResults questionData={freeTextData} />;
  }

  return (
    <>
      <Box
        borderRadius="md"
        bgColor="whitesmoke"
        padding="10"
        marginBottom="10"
      >
        <Box
          d="flex"
          flexDir={{ md: "row", base: "column" }}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stat paddingRight="10">
            <StatLabel>{k("report.module.label.average")}</StatLabel>
            <StatNumber>{module.value}</StatNumber>
            <StatHelpText></StatHelpText>
          </Stat>
          <Box flex="1" textAlign="left">
            <Heading fontSize="18" marginBottom="3">
              {t(module.name)}
            </Heading>
            <Text>{t(module.moduleDescription)}</Text>
          </Box>
        </Box>
        <HorizontalBar
          chartData={questionSetData}
          height={
            questionSetData.length * 20 > 50 ? questionSetData.length * 20 : 50
          }
        />
      </Box>
      <Accordion allowToggle={true} allowMultiple={true}>
        <AccordionItem borderTop="none" marginBottom="3">
          <AccordionButton
            borderRadius="md"
            boxShadow="sm"
            background="yellow.300"
          >
            <Box
              fontFamily="Red Hat Display Bold"
              flex="1"
              textAlign="left"
              d="flex"
              flexDir="row"
              alignItems="center"
            >
              <Icon fontSize="20" as={BiBarChartSquare} marginRight="3" />
              {k("report.module.button.questions")}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel paddingX="0">
            <HorizontalBar
              chartData={perQuestionData}
              height={horizontalHeight}
            />
          </AccordionPanel>
        </AccordionItem>
        {trend && trend.values.length > 1 && <TrendPanel trend={trend} />}
        {props.displaySelfAssessment && (
          <SelfAssessmentPanel module={module} surveyId={surveyId} />
        )}
        {freeTextData.length !== 0 && (
          <FreeTextPanel questionData={freeTextData} />
        )}
      </Accordion>
    </>
  );
};

export default ModuleInfo;
