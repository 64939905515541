import { useEffect, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useTraceUpdate } from "./hooks/useTraceUpdate";
import LanguageProvider from "./provider/Language";
import { Language } from "./translations/translations";
import SurveyReport from "./ui/views/Report";
import { clearStorage } from "./utils/auth";

export interface ReportProps {
  reportId: string;
  userId: string;
  language: Language;
  isManager?: boolean;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // prevent refetching when window gains focus
      retry: false, // don't refetch if query fails
      staleTime: 1000 * 60 * 5, // mark data as fresh for 5 minutes before it's considered stale
    },
  },
});

/**
 * This is the entrypoint to the new Reports module
 * There are two ways to use Blalab's new reports:
 * 1. As a stand alone application as seen on this repository's App.tsx
 * 2. As a react-component, as a part of another component
 * which will be built using this component as its root.
 *
 * **Note:** Option 2 requires a `<ChakraProvider>` initialized with a theme
 * outside of the scope of this component.
 **/
const ReportContainer = ({
  reportId,
  userId,
  language,
  isManager,
}: ReportProps) => {
  useTraceUpdate({ reportId, userId, language });
  const report = useMemo(() => reportId, [reportId]);
  const user = useMemo(() => userId, [userId]);
  const lang = useMemo(() => language, [language]);
  const isManaging = useMemo(() => isManager || false, [isManager]);

  // Clear storage using clearStorage if isManager is false
  useEffect(() => {
    if (!isManaging) {
      clearStorage();
    }
  }, [isManaging]);

  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider lang={lang}>
        <SurveyReport reportId={report} isManager={isManaging} userId={user} />
      </LanguageProvider>
    </QueryClientProvider>
  );
};

export default ReportContainer;
