import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Icon,
} from "@chakra-ui/react";
import { Text } from "../Typography/typography";
import LineChart from "../charts/LineChart";
import { TrendingModule } from "../../../models/trends";
import { useContext, useMemo } from "react";
import { Translations } from "../../../provider/Language";
import { BiChart } from "react-icons/bi";

interface Props {
  trend: TrendingModule;
}

const TrendPanel = (props: Props) => {
  const { t, k } = useContext(Translations);
  const { trend } = props;
  const trendData = useMemo(
    () =>
      trend.sets.map((set) => ({
        values: set.values.map((v) => v.value),
        label: t(set.name),
      })),
    [trend, t]
  );

  const trendLabels = useMemo(
    () =>
      trend.sets[0].values.map((v) =>
        new Date(v.createdAt).toLocaleDateString()
      ),
    [trend]
  );

  return (
    <AccordionItem border="none" marginBottom={3}>
      <AccordionButton boxShadow="sm" background="yellow.300" borderRadius="md">
        <Box
          fontFamily="Red Hat Display Bold"
          flex="1"
          textAlign="left"
          d="flex"
          flexDir="row"
          alignItems="center"
        >
          <Icon fontSize="20" as={BiChart} marginRight="3" />
          {k(
            trend
              ? "report.module.button.trends"
              : "report.module.button.trends.disabled"
          )}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel paddingX="0" textAlign="left">
        <Box padding="5">
          <Text padding="3">
            {k("report.section.trends.card.introduction")}
          </Text>
          <Text padding="3">{k("report.results.charts.trends.intro")}</Text>
        </Box>
        <LineChart chartData={trendData} xAxis={trendLabels} />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default TrendPanel;
