import create from "zustand";
import { getSurveys } from "../core/api";
import { Report } from "../models/report";

interface State {
  surveys: Array<Report>;
}

interface ReportState extends State {
  getSurveys: (teamId: string) => Promise<void>;
}

export const surveyStore = create<ReportState>((set) => ({
  surveys: [],
  getSurveys: async (teamId: string) => {
    const surveys = await getSurveys(teamId);
    return set({ surveys: surveys });
  },
}));

export const getAvailableReports = (state: ReportState) =>
  state.surveys.filter((s) => !!s.canBeViewed);
