import create from "zustand";
import { getTeams } from "../core/api";
import { Team } from "../models/team";

interface State {
  teams: Array<Team>;
}

interface TeamState extends State {
  getTeams: () => Promise<void>;
}

export const teamStore = create<TeamState>((set, get) => ({
  teams: [],
  getTeams: async () => {
    const teams = await getTeams();
    return set({ teams: teams });
  },
}));
