import { Box, Flex, Text } from "@chakra-ui/layout";
import React, { useContext } from "react";
import { QuestionSetFreeTextAnswers } from "../../../../models/report";
import { Translations } from "../../../../provider/Language";
import FreeTextValue from "./FreeTextValue";

interface Props {
  results: QuestionSetFreeTextAnswers;
}

const QuestionSetFreeTextResults = ({ results }: Props) => {
  const { t } = useContext(Translations);
  return (
    <Flex direction="column" textAlign="start" gridGap={4}>
      <Text fontWeight="bold" fontSize="lg">
        {t(results.questionSetName)}
      </Text>
      <Flex direction="column" gridGap={8} pl={2}>
        {results.questions.map((item, i) => (
          <Box key={`question-${i}`}>
            <Text fontWeight="bold" py={4} fontSize="md" mb={2}>
              {t(item.name)}
            </Text>
            <Flex direction="column" gridGap={4} pl={2}>
              {((item.value ?? []) as string[]).map((val, i) => (
                <FreeTextValue value={val} key={`question-value-${i}`} />
              ))}
            </Flex>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default QuestionSetFreeTextResults;
