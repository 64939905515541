import { extendTheme } from "@chakra-ui/react";

const defaultHoverProps = {
  cursor: "pointer",
  shadow: "md",
  transform: "scale(1.02)",
  transition: "all 0.2s",
};

const theme = extendTheme({
  fonts: {
    default: "Open Sans",
    body: "Open Sans",
    heading: "Red Hat Display",
  },
  colors: {
    brand: {
      default: "yellow.300",
      light: "yellow.200",
      dark: "yellow.500",
    },
    secondary: {
      default: "blue.600",
      light: "blue.500",
      dark: "blue.700",
    },
  },
  actions: {
    hover: {
      default: {
        _hover: defaultHoverProps,
      },
      danger: {
        _hover: {
          ...defaultHoverProps,
          bg: "red.100",
        },
      },
      icon: {
        _hover: {
          cursor: "pointer",
          filter: "drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.4))",
        },
      },
      table: {
        cursor: "pointer",
        bg: "yellow.100",
      },
    },
  },
  components: {
    Tab: {
      variants: {
        button: {
          border: "2px solid",
          bgColor: "transparent",
          borderColor: "yellow.300",
          color: "yellow.300",
          _hover: {
            bg: "yellow.300",
            color: "black",
            bgColor: "yellow.300",
            border: "2px solid",
            borderColor: "yellow.300",
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "lg",
        fontFamily: "Red Hat Display",
        letterSpacing: "0.05rem",
        boxShadow: "base",
        _hover: {
          bg: "yellow.400",
          color: "white",
          bgColor: "yellow.400",
          border: "2px solid",
          borderColor: "yellow.400",
        },
        _disabled: {
          bg: "yellow.400",
          color: "white",
          bgColor: "yellow.400",
          border: "2px solid",
          borderColor: "yellow.400",
        },
        ":hover[disabled]": {
          bg: "yellow.300",
        },
      },
      variants: {
        icon: {
          bg: "blue.400",
          bgColor: "blue.400",
          color: "white",
          border: "2px solid",
          borderColor: "blue.400",
          borderRadius: "xl",
          boxShadow: "base",
          _hover: {
            bg: "blue.700",
            color: "white",
            bgColor: "blue.700",
            border: "2px solid",
            borderColor: "blue.700",
          },
        },
        secondary: {
          bg: "blue.400",
          bgColor: "blue.400",
          color: "white",
          border: "2px solid",
          borderColor: "blue.400",
          boxShadow: "base",
          _hover: {
            bg: "blue.700",
            color: "white",
            bgColor: "blue.700",
            border: "2px solid",
            borderColor: "blue.700",
          },
        },
        outline: {
          border: "2px solid",
          bgColor: "transparent",
          borderColor: "purple.600",
          color: "purple.600",
          _hover: {
            bg: "purple.600",
            color: "white",
            bgColor: "purple.600",
            border: "2px solid",
            borderColor: "purple.600",
          },
        },

        solid: {
          bg: "yellow.300",
          color: "black",
          border: "2px solid",
          borderColor: "yellow.300",
        },
        danger: {
          bg: "red.300",
          color: "white",
          _hover: {
            bgColor: "red.500",
            border: "0px solid",
            borderColor: "red.500",
            _disabled: {
              bgColor: "red.300",
            },
          },
        },
        callToAction: {
          bg: "purple.600",
          color: "white",
          border: "2px solid",
          borderColor: "purple.600",
          boxShadow: "md",
          _hover: {
            bgColor: "purple.500",
            borderColor: "purple.500",
            _disabled: {
              bgColor: "purple.300",
            },
          },
        },
      },
    },
    Input: {
      baseStyle: {
        boxShadow: "md",
        outline: "none",
        border: 0,
      },
      defaultProps: {
        variant: "filled",
      },
      variants: {
        outline: {
          boxShadow: "md",
          outline: "none",
          border: 0,
        },
        valid: {
          bg: "blue.100",
        },
        invalid: {
          bg: "red.100",
        },
      },
    },
    Badge: {
      baseStyle: {
        fontFamily: "Red Hat Display",
        color: "gray.800",
        border: "2px solid",
        fontWeight: "normal",
        textTransform: "uppercase",
        borderColor: "transparent",
        _hover: {
          cursor: "pointer",
          border: "2px solid",
          borderColor: "gray.600",
        },
      },
      variants: {
        selected: {
          fontSize: "md",
          boxShadow: "md",
          color: "gray.800",
          p: 5,
          _hover: {
            cursor: "pointer",
          },
        },
      },
    },
    Table: {
      variants: {
        default: {
          tr: {
            _odd: {
              background: "whitesmoke",
              borderRadius: "md",
            },
          },
        },
      },
    },
  },
});

export default theme;
