import { Button } from "@chakra-ui/button";
import { Box, Center, Link, List, ListItem } from "@chakra-ui/layout";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { userStore } from "../../../state/user";
import { clearStorage, getToken } from "../../../utils/auth";
import { Card } from "../../components/atoms/Card";
import { Heading, Text } from "../../components/Typography/typography";
import DemoTeams from "./DemoTeams";

const Demo = () => {
  const { user, getUser } = userStore();
  const token = getToken();
  const navi = useHistory();

  const resolveUser = useCallback(async () => {
    try {
      await getUser();
    } catch (e) {
      clearStorage();
    }
  }, [getUser]);

  const handleSignOut = () => {
    clearStorage();
    return navi.push(`/login?redirection=/demo`);
  };

  useEffect(() => {
    if (!token) {
      return navi.push(`/login?redirection=/demo`);
    }
    resolveUser();
  }, [token, navi, resolveUser]);

  return (
    <Center flexDir="column" minH="100vh" p="5">
      {!user ? (
        <Card p="5">
          <Heading>Loading user...</Heading>
        </Card>
      ) : (
        <Card>
          <Heading fontSize="25" paddingY="5">
            Internal Demo Page
          </Heading>
          <Box p="3" bg="whitesmoke" d="flex" alignItems="center" flexDir="row">
            <Text mr="5">Signed in as {user.email}</Text>
            <Button onClick={handleSignOut}>Sign out</Button>
          </Box>
          <Text fontSize="18" paddingY="5">
            This demo page includes links to presentable content on the
            reports-platform.
          </Text>
          <Heading fontSize="18" paddingY="5">
            User views
          </Heading>
          <Text fontSize="18" paddingBottom="5">
            The data here is static and not necessarily related to this manager.
          </Text>
          <List>
            <ListItem>
              <Link href="/report/en/4d7e8b56/41696fd4">
                Organization Analysis - User view
              </Link>
            </ListItem>
            <ListItem>
              <Link href="/report/en/7a9b3dee/5c56f5cb">BIM - User view</Link>
            </ListItem>
          </List>
          <DemoTeams />
        </Card>
      )}
    </Center>
  );
};

export default Demo;
