import axios, { AxiosResponse, Method, AxiosError } from "axios";
import { HttpError } from "../models/workspace";

function getConfig(method: Method) {
  const token = sessionStorage.getItem("jwt");
  return {
    method,
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": "application/json;charset=utf-8",
    },
  };
}

const handleError = (err: AxiosError) => {
  const { response } = err;
  if (!response) {
    throw new HttpError("Unknown error", 500);
  }
  const message = response.data.description;
  throw new HttpError(message, response.data.code);
};

export function get<T>(url: string) {
  return axios
    .get(url, getConfig("get"))
    .then((data: AxiosResponse) => {
      return data as AxiosResponse<T>;
    })
    .catch(handleError);
}

export function axiosDelete(url: string) {
  return axios
    .delete(url, getConfig("delete"))
    .then((response: AxiosResponse) => response)
    .catch(handleError);
}

export async function post<T>(url: string, body: any) {
  try {
    const response = await axios.post<T>(url, body, getConfig("post"));
    return response;
  } catch (err) {
    return handleError(err as AxiosError);
  }
}
