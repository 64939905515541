import Icon from "@chakra-ui/icon";
import { Box, Center } from "@chakra-ui/layout";
import { useCallback, useContext, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  getFeed,
  MessageInput,
  saveFeedMessageWithToken,
  saveFeedMessage,
} from "../../../core/api";
import { ChatMessage } from "../../../models/chat";
import { HttpError } from "../../../models/workspace";
import { Heading } from "../Typography/typography";
import { BiChat } from "react-icons/bi/";
import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import DiscussionMessages from "../DiscussionMessages/DiscussionMessages";
import { Translations } from "../../../provider/Language";

interface Props {
  userId: string;
  surveyId: string;
  teamId: string;
  isOpen: boolean;
  isManager: boolean;
  onClose: () => void;
}

const DiscussionDrawer = (props: Props) => {
  const { k } = useContext(Translations);
  const { userId, surveyId, teamId, isOpen, isManager, onClose } = props;
  const [feed, setFeed] = useState<Array<ChatMessage>>([]);
  const [error, setError] = useState<HttpError | null>(null);
  const [newMessage, setNewMessage] = useState<string>("");
  const queryClient = useQueryClient();

  const submitMessage = useMemo(
    () => (isManager ? saveFeedMessageWithToken : saveFeedMessage),
    [isManager]
  );

  useQuery(["feed", userId, surveyId], () => getFeed(userId, surveyId!), {
    onSuccess: (data) => setFeed(data),
    onError: setError,
    enabled: !!surveyId && !error,
  });

  const createMessage = useCallback(
    (message: string): MessageInput => {
      return {
        responseId: userId,
        text: message,
        surveyId,
        teamId,
      };
    },
    [userId, surveyId, teamId]
  );

  const handleSubmit = async (
    e:
      | React.FormEvent<HTMLFormElement | HTMLDivElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (newMessage.length === 0) {
      return;
    }

    try {
      await submitMessage(createMessage(newMessage));
      queryClient.invalidateQueries(["feed", userId, surveyId]);
      setNewMessage("");
    } catch (e) {
      setError(e as HttpError);
    }
  };

  return (
    <Drawer
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
    >
      <DrawerOverlay />
      <DrawerContent bgColor="gray.800">
        <DrawerHeader
          d="flex"
          flexDir="row"
          justifyContent="center"
          alignItems="center"
        >
          <Heading color="yellow.300" fontSize="20" flex="1">
            <Icon fontSize="30" as={BiChat} marginRight="5" />
            {k("discussion.sidebar.title")}
          </Heading>
          <DrawerCloseButton
            left="0"
            top="0"
            alignSelf="flex-end"
            bgColor="yellow.300"
            borderRadius="full"
            _hover={{ background: "#fff" }}
            position="relative"
          />
        </DrawerHeader>
        <DrawerBody bgColor="whitesmoke" display="flex">
          {feed.length === 0 ? (
            <Center flex="1">
              <Heading color="GrayText" fontSize="15">
                {k("report.chat.empty")}
              </Heading>
            </Center>
          ) : (
            <DiscussionMessages userId={userId} feed={feed} />
          )}
        </DrawerBody>
        <DrawerFooter>
          <Box
            w="100%"
            d="flex"
            flexDir="row"
            as="form"
            onSubmit={(e: React.FormEvent<HTMLFormElement | HTMLDivElement>) =>
              handleSubmit(e)
            }
          >
            <Input
              _focus={{
                borderColor: "yellow.300",
                background: "whitesmoke",
                borderWidth: "2px",
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewMessage(e.target.value)
              }
              marginRight="3"
              flex="1"
              placeholder="Wish to say something?"
            />
            <Button onClick={(e) => handleSubmit(e)}>Submit</Button>
          </Box>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DiscussionDrawer;
