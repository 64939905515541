import { Box } from "@chakra-ui/layout";
import * as React from "react";
import { Radar } from "react-chartjs-2";
import { chartColors, opaqueChartColors } from "./colors";

interface Props {
  label: string;
  chartData: Array<{ name: string; value: number; description?: string }>;
}

function createChartDataObject(
  data: Array<{ name: string; value: number; description?: string }>
) {
  return {
    labels: data.map((item) => item.name),
    datasets: [
      {
        data: data.map((item) => item.value), // TODO: replace with actual value
        backgroundColor: opaqueChartColors[0],
      },
    ],
  };
}

function createChartOptions(label: string): Chart.ChartOptions {
  return {
    scale: {
      ticks: {
        max: 6,
        min: 0,
        stepSize: 1,
        beginAtZero: true,
        font: {
          size: 14,
          family: "Red Hat Display",
        },
      },
    },
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 5,
        pointLabels: {
          font: {
            size: 13,
            family: "Red Hat Display Bold",
          },
          callback: function (label: string) {
            return label.split(" ");
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        borderColor: chartColors[0],
      },
      point: {
        fontFamily: "Red Hat Display",
        radius: 8,
        borderColor: chartColors[0],
        borderWidth: 2,
        hoverRadius: 10,
        hoverBorderWidth: 2,
      },
    },
    plugins: {
      title: {
        display: false,
        text: label,
      },
      legend: {
        display: false,
        position: "top",
      },
    },
  } as any;
}

export const RadarChart = ({ chartData, label }: Props) => {
  const dataset = React.useMemo(
    () => createChartDataObject(chartData),
    [chartData]
  );
  const options = React.useMemo(() => createChartOptions(label), [label]);
  return (
    <Box w={{ base: "500px", md: "400px", sm: "300px" }}>
      <Radar data={dataset} options={{ ...options, animation: false } as any} />
    </Box>
  );
};

export default RadarChart;
