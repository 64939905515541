import * as React from "react";
import {
  Text as ChakraText,
  TextProps as ChakraTextProps,
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from "@chakra-ui/react";
import "../../../assets/fonts/fonts.css";

interface HeadingProps extends ChakraHeadingProps {
  title?: string;
}

export const Heading: React.FC<HeadingProps> = ({
  title,
  children,
  ...rest
}) => (
  <ChakraHeading fontFamily="Red Hat Display" {...rest}>
    {title || children}
  </ChakraHeading>
);

interface TextProps extends ChakraTextProps {
  content?: string;
}

export const Text: React.FC<TextProps> = ({ content, children, ...rest }) => (
  <ChakraText fontFamily="Open Sans" {...rest}>
    {content || children}
  </ChakraText>
);

export const HeadingOne: React.FC<ChakraHeadingProps> = ({
  children,
  ...rest
}) => (
  <ChakraHeading fontFamily="Red Hat Display" fontSize="4xl" as="h1" {...rest}>
    {children}
  </ChakraHeading>
);

export const HeadingTwo: React.FC<ChakraHeadingProps> = ({
  children,
  ...rest
}) => (
  <ChakraHeading fontFamily="Red Hat Display" fontSize="2xl" as="h2" {...rest}>
    {children}
  </ChakraHeading>
);

export const HeadingThree: React.FC<ChakraHeadingProps> = ({
  children,
  ...rest
}) => (
  <ChakraHeading fontFamily="Red Hat Display" fontSize="xl" as="h3" {...rest}>
    {children}
  </ChakraHeading>
);
