export const getToken = (): string | null => {
  const sessionToken = sessionStorage.getItem("jwt");
  const storedtoken = localStorage.getItem("jwt");
  if (sessionToken && sessionToken !== storedtoken) {
    return sessionToken;
  }

  if (storedtoken) {
    return storedtoken;
  }

  return null;
};

export const setToken = (token: string, persist: boolean = false) => {
  if (!sessionStorage || !localStorage) {
    return false;
  }

  sessionStorage.setItem("jwt", token);
  if (persist) {
    localStorage.setItem("jwt", token);
    return true;
  }
  return true;
};

export const clearStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};
