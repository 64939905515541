import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";
import Icon from "@chakra-ui/icon";
import { Flex } from "@chakra-ui/layout";
import { useContext } from "react";
import { BiText } from "react-icons/bi";
import { QuestionSetFreeTextAnswers } from "../../../../models/report";
import { Translations } from "../../../../provider/Language";
import FreeTextResults from "./FreeTextResults";

interface Props {
  questionData: QuestionSetFreeTextAnswers[];
}

const FreeTextQuestions = ({ questionData }: Props) => {
  const { k } = useContext(Translations);
  return (
    <AccordionItem borderTop="none" marginBottom="3">
      <AccordionButton borderRadius="md" boxShadow="sm" background="yellow.300">
        <Flex
          fontFamily="Red Hat Display Bold"
          flex="1"
          textAlign="left"
          d="flex"
          flexDir="row"
          alignItems="center"
        >
          <Icon fontSize="20" as={BiText} marginRight="3" />
          {k("report.openquestions.title")}
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel paddingX="0">
        <FreeTextResults questionData={questionData} />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default FreeTextQuestions;
