import { useCallback, useMemo, useState } from "react";
import { Box, Center } from "@chakra-ui/layout";
import { Divider, Skeleton, SkeletonText } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { fetchUser, getReport, getTrendsByRespondent } from "../../core/api";
import { Report } from "../../models/report";
import { ReportTrends, ReportWithTrends } from "../../models/trends";
import { HttpError } from "../../models/workspace";
import { Card } from "../components/atoms/Card";
import ReportDetails from "../components/ReportDetails/ReportDetails";
import ReportHeader from "../components/ReportHeader/ReportHeader";
import SurveyInfo from "../components/SurveyInfo/SurveyInfo";
import { appendModulesWithTrends } from "../../core/report";
import DiscussionDrawer from "../components/DiscussionDrawer/DiscussionDrawer";
import ReportActions from "../components/ReportActions/ReportActions";
import { getUserLevel, userStore } from "../../state/user";
import { getToken } from "../../utils/auth";

interface Props {
  reportId: string;
  userId: string;
  isManager: boolean;
}

const SurveyReport = ({ reportId, userId, isManager }: Props) => {
  const [report, setReport] = useState<Report | null>(null);
  const [error, setError] = useState<HttpError | null>(null);
  const [reportWithTrends, setTrendReport] = useState<ReportWithTrends | null>(
    null
  );
  const [drawerOpen, toggleDrawer] = useState<boolean>(false);
  const userLevel = userStore(getUserLevel);
  const { setUser } = userStore();

  const surveyId: string | null = useMemo(
    () => (report ? report.surveyId : null),
    [report]
  );

  const handleToggleDrawer = () => {
    toggleDrawer((prev) => !prev);
  };

  const handleTrendsLoaded = useCallback(
    (trends: ReportTrends, report: Report) => {
      const reportWithTrends = appendModulesWithTrends(trends, report);
      return setTrendReport(reportWithTrends);
    },
    []
  );

  useQuery(["report", reportId], () => getReport(reportId, userId), {
    onSuccess: (data) => setReport(data.report),
    onError: setError,
    enabled: report === null && error === null,
  });

  useQuery(
    ["trends", surveyId, userId],
    () => getTrendsByRespondent(surveyId!, userId),
    {
      onSuccess: (trends) => handleTrendsLoaded(trends, report!),
      onError: setError,
      enabled: !!surveyId,
    }
  );

  const { isLoading: userLoading } = useQuery(
    ["user", userId],
    () => fetchUser(),
    {
      onSuccess: (user) => setUser(user),
      onError: setError,
      enabled: !!getToken(),
    }
  );

  if (error) {
    return <span>{error.message}</span>;
  }

  const isCommentingEnabled =
    ["MANAGER", "FREE"].includes(userLevel) &&
    surveyId &&
    report &&
    !userLoading;

  return (
    <>
      <Center
        alignItems="flex-start"
        w="100vw"
        minH="100vh"
        padding={{ lg: 10, md: 8, sm: 4 }}
      >
        <Card
          d="flex"
          maxW="100vw"
          width="1200px"
          flexDir="row"
          padding="0"
          overflow="hidden"
          boxShadow="lg"
          borderRadius="lg"
          border={{ base: "none" }}
        >
          <Box d="flex" flexDir="column" maxW="100%" w="100%">
            {Boolean(report) ? (
              <Box
                d="flex"
                marginX={{ base: 2, md: 8, lg: 10 }}
                flexDir={{ md: "row", base: "column" }}
                alignItems={{ md: "center", base: "flex-start" }}
              >
                <ReportHeader report={report!} />
                <ReportActions
                  toggleChat={handleToggleDrawer}
                  reportId={reportId}
                  userId={userId}
                  isManager={isManager}
                />
              </Box>
            ) : (
              <SkeletonText noOfLines={4} margin="10" />
            )}
            <Divider />
            <Box paddingX={{ lg: 10, md: 8, base: 2 }}>
              {Boolean(report) ? (
                <SurveyInfo report={report!} />
              ) : (
                <Skeleton h="400" noOfLines={4} margin="10" />
              )}
              <Divider variant="dashed" />
              {reportWithTrends ? (
                <ReportDetails
                  report={reportWithTrends}
                  isManager={isManager}
                />
              ) : (
                <Skeleton h="400" noOfLines={4} margin="10" />
              )}
            </Box>
          </Box>
        </Card>
      </Center>
      {isCommentingEnabled && (
        <DiscussionDrawer
          isManager={isManager}
          isOpen={drawerOpen}
          onClose={() => toggleDrawer(false)}
          surveyId={surveyId}
          teamId={report.teamId}
          userId={userId}
        />
      )}
    </>
  );
};

export default SurveyReport;
