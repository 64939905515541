export interface WorkspaceViewProps {
  surveyId: string;
  userId: string;
}

export interface StoreMeta {
  loading: boolean;
  ready: boolean;
  error: boolean;
}

export type TranslatableProperty = {
  fi: string;
  se: string;
  en: string;
};

export class HttpError extends Error {
  errorCode: number;

  constructor(message: string, errorCode: number = 500) {
    super(message);
    this.errorCode = errorCode;
  }
}
